<template>
  <Dialog
    v-model:visible="isOpen"
    :closable="false"
    :closeOnEscape="true"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>แหล่งที่มาของรายได้ของครอบครัว</h3>
    </template>
    <div class="p-grid">
      <div class="p-col-4" v-for="(option, key) in radios" :key="key">
        <div class="p-field-checkbox">
          <Checkbox
            :id="option.text"
            :name="option.value"
            v-model="selects"
            :value="key"
          />
          <label :for="option.value">{{ option.text }}</label>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => setModal(false)"
      />
      <Button
        label="ยืนยันผล"
        icon="pi pi-check"
        autofocus
        @click="setLabelValueAndCloseModal"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalIncomeSourceScore',
  data() {
    return {
      selects: null,
      radios: [
        { value: 1, text: 'เบี้ยยังชีพผู้สูงอายุ' },
        { value: 1, text: 'เบี้ยผู้พิการ' },
        { value: 1, text: 'จากการทำงานในปัจจุบัน' },
        { value: 1, text: 'บำเหน็จ/บำนาญ' },
        { value: 1, text: 'ดอกเบี้ยเงินฝากธนาคาร' },
        { value: 1, text: 'จากบุตร/สมาชิกในครอบครัว' },
        { value: 1, text: 'ค่าเช่าที่ดินหรือบ้าน' },
        { value: 1, text: 'บัตรสวัสดิการแห่งรัฐ ' },
        { value: 1, text: 'อื่นๆ ระบุ' }
      ]
    }
  },
  computed: {
    isOpen() {
      return this.$store.getters['prediction/isModalIncomeSourceScore']
    }
  },
  methods: {
    setModal(isOpen) {
      this.$store.commit('prediction/setModal', {
        modalName: 'income_source_score',
        isOpen
      })
    },
    setLabelValueAndCloseModal() {
      // setLabelValue
      console.log(' this.selects ::==', this.selects)
      const value = this.selects?.length
      console.log('value ::==', value)
      this.$store.commit('prediction/setLabelValue', {
        labelName: 'income_source_score',
        value
      })
      this.setModal(false)
    }
  }
}
</script>

<style>
</style>