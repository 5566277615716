<template>
  <Dialog
    v-model:visible="isOpen"
    :closable="false"
    :closeOnEscape="true"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>
        ระบุการเจ็บป่วยหรือโรคประจำตัวของท่านที่มีผลทางด้านร่างกาย
        <br /><strong>(โรคที่แพทย์เป็นผู้วินิจฉัยและให้การรักษา)</strong>
      </h3>
    </template>

    <div class="p-field-radiobutton">
      <RadioButton id="no" name="group" :value="false" v-model="group" />
      <label for="group">ไม่มี</label>
    </div>
    <div class="p-field-radiobutton">
      <RadioButton id="yes" name="group" :value="true" v-model="group" />
      <label for="group">มี โปรดระบุ</label>
    </div>

    <div class="p-field p-col-12 p-sm-12">
      <div class="p-grid">
        <div class="p-col-12 p-sm-4" v-for="(option, key) in radios" :key="key">
          <div class="p-field-checkbox">
            <Checkbox
              :id="option.text"
              :name="option.value"
              v-model="selects"
              :value="key"
              :disabled="!group"
            />
            <label :for="option.value">{{ option.text }}</label>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        class="p-button-text"
        @click="() => setModal(false)"
      />
      <Button
        label="ยืนยันผล"
        icon="pi pi-check"
        autofocus
        @click="setLabelValueAndCloseModal"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'modalHealthBodyCount',
  data() {
    return {
      selects: null,
      group: false,
      radios: [
        { value: 1, text: 'ความดันโลหิตสูง' },
        { value: 1, text: 'เบาหวาน' },
        { value: 1, text: 'โรคหัวใจ' },
        { value: 1, text: 'โรคหลอดเลือดสมอง' },
        { value: 1, text: 'โรคปอด/หอบหืด' },
        { value: 1, text: 'โรคมะเร็ง' },
        { value: 1, text: 'มีอาการทางจิตเวช' },
        { value: 1, text: 'โรคอ้วน' },
        { value: 1, text: 'โรคเก๊าท์/ข้อเสื่อม/กระดูกเสื่อม' },
        { value: 1, text: 'ความจำเสื่อม/สมองเสื่อม' },
        { value: 1, text: 'ซึมเศร้า' },
        { value: 1, text: 'อื่นๆ ระบุ' }
      ]
    }
  },
  computed: {
    isOpen() {
      return this.$store.getters['prediction/isModalDiseaseTotal']
    }
  },
  watch: {
    group: function (newVal, oldVal) {
      if (!newVal) {
        this.selects = null
      }
    }
  },
  methods: {
    setModal(isOpen) {
      this.$store.commit('prediction/setModal', {
        modalName: 'disease_total',
        isOpen
      })
    },
    setLabelValueAndCloseModal() {
      // setLabelValue
      console.log(' this.selects ::==', this.selects)
      const value = this.selects?.length
      console.log('value ::==', value)
      this.$store.commit('prediction/setLabelValue', {
        labelName: 'disease_total',
        value
      })
      this.setModal(false)
    }
  }
}
</script>

<style>
</style>